"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VanillaBot = void 0;
var marked_1 = require("marked");
var css_injector_service_1 = require("../../services/css-injector.service");
var bot_stylings_const_1 = require("./css/bot-stylings.const");
var send_requests_1 = require("./services/send-requests");
var VanillaBot = /** @class */ (function () {
    function VanillaBot() {
        this.path = '';
        this.colorPallete = {
            primaryBackground: '#000',
            primaryHighlight: '#fff',
            background: '#fff',
            borderAccent: '#aaa',
            typingAnimationColor: '#9880ff',
            linkColor: '#000',
            linkCss: {
                color: '#000',
                'font-weight': 'bold',
                'text-decoration': 'underline',
            },
            unreadMessages: { background: 'red' },
            answerBubble: {
                background: '#f4f4f4',
                color: '#000',
            },
        };
        this.wrapper = document.createElement('div');
        this.chatOpen = false;
        this.firstOpen = false;
        this.animationStatus = true;
        this.shittyConversation = false;
    }
    VanillaBot.prototype.loadModule = function (settings) {
        var _this = this;
        var _a, _b;
        this.settings = settings;
        if ((settings === null || settings === void 0 ? void 0 : settings.demo) && !document.location.href.includes('fitki-demo-bypass')) {
            return;
        }
        if ((_a = this.settings) === null || _a === void 0 ? void 0 : _a.tenant) {
            console.error('No tenant defined');
        }
        if (settings.name) {
            this.botName = settings.name;
        }
        if (settings === null || settings === void 0 ? void 0 : settings.path) {
            this.path = settings.path;
        }
        if (settings.colorPallete) {
            this.colorPallete = __assign(__assign({}, this.colorPallete), settings.colorPallete);
        }
        // Define the CSS styles
        css_injector_service_1.CssInjectorService.injectCss('_BotStylings', (0, bot_stylings_const_1._BotStylings)(this.colorPallete));
        var chatFenster = document.createElement('div');
        chatFenster.className = 'fittec-chatfenster w-container fittec-expand-height-bottom';
        var chatNavigation = document.createElement('div');
        chatNavigation.className = 'fittec-chat-navigation w-container';
        var nameGpt = document.createElement('div');
        nameGpt.className = 'fittec-name-gpt';
        nameGpt.innerText = (_b = this.botName) !== null && _b !== void 0 ? _b : 'Gymbro Alex';
        var closeIcon = document.createElement('img');
        closeIcon.className = 'fittec-close-icon';
        closeIcon.src =
            'https://files.scaleyourgym.com/files/webflow/new-ki/assets/exit.svg';
        closeIcon.alt = '';
        closeIcon.addEventListener('click', function () {
            _this.toggleChatWindow();
        });
        chatNavigation.appendChild(nameGpt);
        chatNavigation.appendChild(closeIcon);
        chatFenster.appendChild(chatNavigation);
        var chatVerlauf = document.createElement('div');
        chatVerlauf.className = 'fittec-chatverlauf w-container';
        this.chatMessageContainer = chatVerlauf;
        chatFenster.appendChild(chatVerlauf);
        var formContainerChat = document.createElement('div');
        formContainerChat.className = 'fittec-form-container-chat w-form';
        var form = document.createElement('form');
        form.id = 'email-form';
        form.name = 'email-form';
        form.method = 'get';
        form.className = 'fittec-chat-input';
        var textarea = document.createElement('textarea');
        textarea.placeholder = 'Deine Nachricht....';
        textarea.maxLength = 5000;
        textarea.id = 'field';
        textarea.name = 'field';
        textarea.className = 'fittec-chat-input-field w-input';
        var sendIconLink = document.createElement('a');
        sendIconLink.href = '#';
        sendIconLink.className = 'fittec-send-icon-link w-inline-block';
        var sendIcon = document.createElement('img');
        sendIcon.className = 'fittec-send-icon';
        sendIcon.src =
            'https://files.scaleyourgym.com/files/webflow/new-ki/assets/send.svg';
        sendIcon.alt = '';
        sendIconLink.addEventListener('click', function () {
            _this.sendMessage(textarea.value);
            textarea.value = '';
        });
        textarea.addEventListener('keyup', function (event) {
            if (event.key === 'Enter') {
                _this.sendMessage(textarea.value);
                textarea.value = '';
            }
        });
        sendIconLink.appendChild(sendIcon);
        form.appendChild(textarea);
        form.appendChild(sendIconLink);
        formContainerChat.appendChild(form);
        chatFenster.appendChild(formContainerChat);
        this.chatWindow = chatFenster;
        this.wrapper.classList.add('fittec-ki-wrapper');
        this.wrapper.appendChild(chatFenster);
        this.startAnimation();
        if (settings.showNumberOfUnreadChats > 0) {
            this.renderUnreadChatsNumber(this.wrapper);
        }
        var chatOpen = document.createElement('div');
        chatOpen.className = 'fittec-chat-open w-container';
        var chatIcon = document.createElement('img');
        chatIcon.className = 'fittec-chat-icon';
        chatIcon.src = 'https://files.scaleyourgym.com/files/webflow/new-ki/assets/chat.svg';
        chatIcon.alt = '';
        chatOpen.addEventListener('click', function () {
            _this.toggleChatWindow();
        });
        chatOpen.appendChild(chatIcon);
        this.wrapper.appendChild(chatOpen);
        document.body.appendChild(this.wrapper);
        if (settings === null || settings === void 0 ? void 0 : settings.openAfterSeconds) {
            setTimeout(function () { _this.toggleChatWindow(); }, ((settings === null || settings === void 0 ? void 0 : settings.openAfterSeconds) * 1000) || 1);
        }
    };
    VanillaBot.prototype.toggleChatWindow = function () {
        var _this = this;
        this.chatWindow.style.display = this.chatOpen ? 'none' : 'block';
        this.chatOpen = !this.chatOpen;
        if (this.chatOpen) {
            this.stopAnimation();
        }
        else {
            this.startAnimation();
        }
        if (this.unreadMessageElement) {
            this.unreadMessageElement.style.display = this.chatOpen ? 'none' : 'flex';
        }
        if (this.firstOpen === false) {
            this.firstOpen = true;
            this.startTyping();
            (0, send_requests_1._PerformRequest)(this.settings.tenant, this.chatId, {
                contentKey: '',
                operation: 'simple',
                tenantKey: this.settings.tenant,
            }, true, this.path).then(function (response) {
                if (response === null || response === void 0 ? void 0 : response.clientId) {
                    _this.chatId = response.clientId;
                }
                if (response === null || response === void 0 ? void 0 : response.conversationId) {
                    _this.conversationId = response.conversationId;
                }
                _this.receiveMessage(response.answer);
            });
        }
    };
    VanillaBot.prototype.stopAnimation = function () {
        this.animationStatus = false;
        this.wrapper.classList.remove('fittec-heartbeat-animation');
    };
    VanillaBot.prototype.startAnimation = function () {
        var _this = this;
        var _a;
        this.animationStatus = true;
        if (((_a = this.settings) === null || _a === void 0 ? void 0 : _a.heartbeatAnimation) !== false) {
            var toggleAnimation_1 = function (isOn) {
                if (_this.animationStatus === true) {
                    if (isOn) {
                        _this.wrapper.classList.add('fittec-heartbeat-animation');
                        setTimeout(function () { return toggleAnimation_1(false); }, 1500);
                    }
                    else {
                        _this.wrapper.classList.remove('fittec-heartbeat-animation');
                        setTimeout(function () { return toggleAnimation_1(true); }, 4000);
                    }
                }
            };
            toggleAnimation_1(true);
        }
    };
    VanillaBot.prototype.receiveMessage = function (message) {
        return __awaiter(this, void 0, void 0, function () {
            var assistantTyping, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.stopTyping();
                        _a = this.receiverBlock;
                        return [4 /*yield*/, (0, marked_1.marked)(message)];
                    case 1:
                        assistantTyping = _a.apply(this, [_b.sent()]);
                        this.chatMessageContainer.appendChild(assistantTyping);
                        this.chatMessageContainer.scrollTop =
                            this.chatMessageContainer.scrollHeight;
                        return [2 /*return*/];
                }
            });
        });
    };
    VanillaBot.prototype.sendMessage = function (message) {
        var _this = this;
        if (message.length === 0) {
            return;
        }
        var chatFrage = document.createElement('div');
        chatFrage.className = 'fittec-chat-frage w-container';
        var iconFrage = document.createElement('img');
        iconFrage.className = 'fittec-icon-antwort';
        iconFrage.src =
            'https://files.scaleyourgym.com/files/webflow/new-ki/assets/person.svg';
        iconFrage.alt = '';
        var chatText2 = document.createElement('div');
        chatText2.className = 'fittec-chat-text';
        chatText2.innerText = message;
        chatFrage.appendChild(iconFrage);
        chatFrage.appendChild(chatText2);
        this.chatMessageContainer.appendChild(chatFrage);
        this.chatMessageContainer.scrollTop =
            this.chatMessageContainer.scrollHeight;
        this.startTyping();
        (0, send_requests_1._PerformRequest)(this.settings.tenant, String(this.chatId), {
            contentKey: message,
            operation: 'simple',
            tenantKey: this.settings.tenant,
        }, false, this.path).then(function (response) {
            _this.receiveMessage(response.answer);
        });
    };
    VanillaBot.prototype.receiverBlock = function (message, typing) {
        var _this = this;
        var _a, _b;
        if (typing === void 0) { typing = false; }
        var chatAntwort = document.createElement('div');
        chatAntwort.className = 'fittec-chat-antwort w-container';
        if ((_a = this.colorPallete) === null || _a === void 0 ? void 0 : _a.answerBubble) {
            for (var key in this.colorPallete.answerBubble) {
                chatAntwort.style[key] = this.colorPallete.answerBubble[key];
            }
        }
        var iconAntwort = document.createElement('img');
        iconAntwort.className = 'fittec-icon-antwort';
        iconAntwort.src =
            ((_b = this === null || this === void 0 ? void 0 : this.settings) === null || _b === void 0 ? void 0 : _b.image) || 'https://files.scaleyourgym.com/files/webflow/new-ki/assets/bot-image.png';
        var chatText1 = document.createElement('div');
        chatText1.className = 'fittec-chat-text';
        chatText1.innerHTML = message;
        var wrapper = document.createElement('div');
        wrapper.appendChild(iconAntwort);
        wrapper.appendChild(chatText1);
        wrapper.className = 'fittec-chat-wrapper';
        chatAntwort.appendChild(wrapper);
        if (typing === false) {
            var dislikeButton_1 = document.createElement('img');
            dislikeButton_1.className = 'fittec-dislike-icon';
            dislikeButton_1.src =
                'https://files.scaleyourgym.com/files/webflow/new-ki/assets/thumb.svg';
            var toolBlock = document.createElement('div');
            toolBlock.className = 'fittec-dislike-block';
            var dislikeText_1 = document.createElement('div');
            dislikeText_1.className = 'fittec-dislike-text';
            dislikeText_1.innerHTML = '';
            toolBlock.addEventListener('click', function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(this.shittyConversation === false)) return [3 /*break*/, 2];
                            dislikeText_1.innerHTML = 'Danke jetzt bin ich etwas schlauer! 💪';
                            dislikeButton_1.style.display = 'none';
                            return [4 /*yield*/, (0, send_requests_1._SendDislike)(String(this.chatId), this.path)];
                        case 1:
                            _a.sent();
                            this.shittyConversation = true;
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); });
            toolBlock.appendChild(dislikeText_1);
            toolBlock.appendChild(dislikeButton_1);
            chatAntwort.appendChild(toolBlock);
        }
        chatAntwort.style.flexDirection = 'column';
        return chatAntwort;
    };
    VanillaBot.prototype.startTyping = function () {
        var assistantTyping = this.receiverBlock(this.botName + ' tippt gerade' || 'Assistent tippt gerade', true);
        var typewriter = document.createElement('div');
        typewriter.className = 'fittec-snippet';
        typewriter.setAttribute('data-title', 'fittec-dot-pulse');
        var stage = document.createElement('div');
        stage.className = 'stage';
        var dotPulse = document.createElement('div');
        dotPulse.className = 'fittec-dot-pulse';
        stage.appendChild(dotPulse);
        typewriter.appendChild(stage);
        assistantTyping.id = 'assistant-typing';
        assistantTyping.children[0].appendChild(typewriter);
        assistantTyping.classList.add('fittec-slide-in-left');
        this.chatMessageContainer.appendChild(assistantTyping);
        this.chatMessageContainer.scrollTop =
            this.chatMessageContainer.scrollHeight;
    };
    VanillaBot.prototype.stopTyping = function () {
        var elements = document.querySelectorAll('#assistant-typing');
        elements.forEach(function (element) { return element.remove(); });
    };
    VanillaBot.prototype.renderUnreadChatsNumber = function (ele) {
        this.unreadMessageElement = document.createElement('span');
        this.unreadMessageElement.className = 'fittec-number-of-unread-elements fittec-expand-from-center';
        this.unreadMessageElement.innerHTML = String(this.settings.showNumberOfUnreadChats);
        ele.appendChild(this.unreadMessageElement);
    };
    return VanillaBot;
}());
exports.VanillaBot = VanillaBot;
